import { poundsToPence } from 'helpers/poundsHelpers';
import { CurrentQuote, getSelectedProductPrices } from 'helpers/useCurrentQuote';
import { PaymentPayload } from 'pages/pet/payment/redirect';
import { StartAnnualPaymentResponse } from '../paymentResponse';

const mapPaymentPayload = (
  paymentDetails: StartAnnualPaymentResponse,
  quote: CurrentQuote
): PaymentPayload => ({
  ...paymentDetails,
  quoteNumber: quote?.policyInfo?.quoteNumber || '',
  paymentAmount: poundsToPence(getSelectedProductPrices(quote)?.annualPrice.total),
  customerPaymentDetails: {
    firstName: quote?.customerInfo?.firstName || '',
    lastName: quote?.customerInfo?.lastName || '',
    email: quote?.customerInfo?.email || '',
    houseNumber:
      quote?.customerInfo?.address.flatNumber ||
      quote?.customerInfo?.address.houseNumber ||
      '',
    postcode: quote?.customerInfo?.address.postcode || '',
  },
  productId: quote.productId,
  selectedBundleCovers: quote.selectedBundleCovers,
  movementDate: paymentDetails.policyMovementUpdateDate,
});

export default mapPaymentPayload;
