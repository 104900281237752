import { GridItemProps } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { QuestionFieldWithMargin } from './styles';

export type DeclarationDetails = {
  hasAgreedToDeclaration: boolean | undefined;
};

type DeclarationProps = {
  id: string;
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  error?: string;
  questionId: string;
  questionText: string;
  gridItemProps?: GridItemProps;
  checkDetailDeclarationStyle?: boolean;
} & ComponentProps;

const Declaration: React.FC<DeclarationProps> = ({
  id,
  hasAgreed,
  updateHasAgreed,
  error,
  questionId,
  questionText,
  children,
  gridItemProps,
  checkDetailDeclarationStyle,
  ...rest
}) => {
  const defaultGridItemProps = { desktop: 8, tabletLandscape: 8, tabletPortrait: 6 };

  return (
    <div {...componentProps(rest)} id={id}>
      {children}
      <QuestionFieldWithMargin
        question={{ questionText }}
        errorText={error}
        gridItemProps={gridItemProps || defaultGridItemProps}
        checkDetailDeclarationStyle={checkDetailDeclarationStyle}>
        <BooleanRadioInput
          id={questionId}
          value={hasAgreed}
          onChange={updateHasAgreed}
          aria-describedby={`${questionId}-error`}
        />
      </QuestionFieldWithMargin>
    </div>
  );
};

export default Declaration;
