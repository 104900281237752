import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import {
  Container,
  Heading,
  QuoteNumberText,
  QuoteReferenceGridItem,
  QuoteReferenceLabel,
} from './styles';

export type SectionHeadingWithQuoteNumberProps = {
  heading: string;
  quoteNumberLabel: string;
  quoteNumber: string;
  id: string;
} & ComponentProps;

const SectionHeadingWithQuoteNumber: React.FC<SectionHeadingWithQuoteNumberProps> = ({
  heading,
  quoteNumberLabel,
  quoteNumber,
  id,
  ...props
}) => (
  <Container {...componentProps(props)}>
    <Grid>
      <QuoteReferenceGridItem>
        <QuoteReferenceLabel>{quoteNumberLabel} </QuoteReferenceLabel>
        <QuoteNumberText>{quoteNumber}</QuoteNumberText>
      </QuoteReferenceGridItem>
      <GridItem>
        <Heading id={id}>{heading}</Heading>
      </GridItem>
    </Grid>
  </Container>
);

export default SectionHeadingWithQuoteNumber;
