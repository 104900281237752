import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Heading = styled.h2`
  ${fonts.headingMedium};
  margin: 0;
`;

export const Container = styled.div`
  margin: ${spacing(4)} 0;

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(6)} 0;
  `}
`;

export const QuoteReferenceLabel = styled.p`
  ${fonts.paragraphLarge};
  display: inline;
  word-wrap: break-word;
  margin-bottom: ${spacing(1)};
`;

export const QuoteNumberText = styled(QuoteReferenceLabel)`
  ${fonts.headingXSmall}
`;

export const QuoteReferenceGridItem = styled(GridItem)`
  margin-bottom: ${spacing(1)};
`;
