import { ContentSpacing } from '@rsa-digital/evo-shared-components/components/Form/Field/ErrorContainer/styles';
import { RadioItem } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import { PanelBodySpacing } from '@rsa-digital/evo-shared-components/components/Panel/LabelledPanel/styles';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { StyledRadioInput } from 'components/BooleanRadioInput/styles';
import Declaration from 'components/Declaration';
import { QuestionFieldWithMargin } from 'components/Declaration/styles';
import RichTextWithModal from 'components/RichTextWithModal';
import { InfoPanel } from 'components/StatusPanel';

export const InfoPanelWithMargin = styled(InfoPanel)`
  margin: 0 0;
  && {
    padding: ${spacing(2)} ${spacing(2)} ${spacing(3)};

    ${mediaQuery.tabletPortrait`
      padding: ${spacing(2)} ${spacing(3)} ${spacing(3)} ${spacing(2)};
    `}

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(2)};
      padding: ${spacing(3)} ${spacing(2)};
    `}
  }

  ${RichText} > :first-child {
    margin-top: 0;
  }
`;

export const RichTextWithFonts = styled(RichTextWithModal)`
  && {
    h3 {
      ${fonts.headingXSmall}
    }

    p {
      ${fonts.paragraph}
      margin-top: ${spacing(0)};

      ${mediaQuery.tabletLandscape`
        ${fonts.paragraphLarge}
      `}
    }
  }
`;

export const GridWithMargin = styled(Grid)`
  && {
    margin-top: ${spacing(0)};
    margin-bottom: ${spacing(0)};
  }
`;

export const DeclarationHeading = styled.h3`
  ${fonts.headingSmall}
  margin: 0;
`;

export const StyledImageDesktop = styled.img`
  display: none;

  ${mediaQuery.tabletPortrait`
    display: inline-block;
    height: ${spacing(4)};
  `}
`;

export const StyledImageMobile = styled.img`
  height: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    display: none;
  `}
`;

export const DeclarationHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LargeRichText = styled(RichTextWithModal)`
  p,
  li {
    ${mediaQuery.tabletLandscape`
      ${fonts.paragraphLarge}
    `}
  }
`;
export const DeclarationWithBottomMargin = styled(Declaration)`
  background-color: ${colors.neutral05};
  border: solid 1px ${colors.neutral03};
  padding: ${spacing(2)};
  margin-top: ${spacing(4)};
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)} ${spacing(3)} ${spacing(3)};
  `};
  > ${Grid} > ${GridItemWrapper} {
    border: none;
    padding: 0;
    margin-bottom: ${spacing(0)};
  }
  && {
    ul {
      padding-top: ${spacing(1)};
      margin-top: ${spacing(0)};
      ${mediaQuery.tabletPortrait`
             padding-top: ${spacing(2)};
    `};
      li {
        ${mediaQuery.tabletPortrait`
               margin: ${spacing(2)} ${spacing(0)} ${spacing(2)} ${spacing(2)};
    `};

        margin: ${spacing(1)} ${spacing(0)} ${spacing(1)} ${spacing(1)};
        padding-left: ${spacing(2)};
        &:before {
          width: ${spacing(1)};
          height: ${spacing(1)};
          left: -${spacing(1)};
          ${mediaQuery.tabletPortrait`
                 left: -${spacing(1.5)};
    `};
        }
      }
    }
  }
  && {
    p + p,
    p + ul,
    ul + p {
      margin-top: ${spacing(3)};
    }
    p + p.small {
      margin-top: ${spacing(2)};
    }
  }
  ${QuestionFieldWithMargin} {
    margin-top: ${spacing(3)};
    margin-bottom: 0;
    background-color: ${colors.neutral08};
    ${mediaQuery.tabletPortrait` 
           margin-top: ${spacing(4)};
           `};
    padding: ${({ error, theme }) =>
      error
        ? 0
        : `${spacing(2)({ theme })} ${spacing(3)({ theme })} ${spacing(3)({
            theme,
          })}`};
    ${mediaQuery.tabletPortrait`
      padding: ${({ error, theme }) =>
        error
          ? 0
          : `${spacing(3)({ theme })} ${spacing(4)({ theme })} ${spacing(4)({ theme })}`};
    `};
    border: ${({ error, theme }) =>
      error ? 'None ' : `1px solid ${colors.neutral02({ theme })}`};
    ${({ error, theme }) =>
      error
        ? `${PanelBodySpacing} {
            padding: 0 ${spacing(2)({ theme })};
            
            ${ContentSpacing} {
              width: 100%;
              margin-right: 0;
              margin-left: 0;  
            }   
          }`
        : ''};
    ${StyledRadioInput} {
      display: flex;
      flex-direction: column;
      gap: ${spacing(2)};
      ${mediaQuery.tabletPortrait`
        flex-direction: row;
        gap: ${spacing(4)};
      `};
      ${RadioItem} {
        margin-left: 0;
      }
    }
  }
`;
