import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import UppPayment from 'components/UppPayment';
import { activeEnv } from 'helpers/activeEnvironment';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { CsAsset } from 'types/contentStack';
import { StyledImage } from './styles';
import SectionHeadingWithIcons from '../../SectionHeadingWithIcons';

type AnnualPaymentContent = {
  csPetAnnualPayment: {
    card_details: {
      heading: string;
      subheading: string;
      card_logos: CsAsset[];
    };
  };
};

export const query = graphql`
  query {
    csPetAnnualPayment {
      card_details {
        heading
        subheading
        card_logos {
          ...CsAsset
        }
      }
    }
  }
`;

type AnnualPaymentSectionProps = {
  quote: CurrentQuote;
};

const AnnualPaymentSection: React.FC<AnnualPaymentSectionProps> = ({ quote }) => {
  const { heading, subheading, card_logos } = useStaticQuery<AnnualPaymentContent>(
    query
  ).csPetAnnualPayment.card_details;

  const sectionId = 'cardPaymentSection';
  const processedImages = card_logos
    .map(processImageAsset)
    .filter((image) => !!image) as Image[];

  return (
    <section aria-labelledby={sectionId}>
      <SectionHeadingWithIcons id={sectionId} heading={heading} subheading={subheading}>
        {processedImages.map((image) => (
          <StyledImage image={image} key={generateKeyForElement(image)} />
        ))}
      </SectionHeadingWithIcons>
      {activeEnv !== 'test' && <UppPayment quote={quote} />}
    </section>
  );
};

export default AnnualPaymentSection;
