import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import { InfoPanel } from 'components/StatusPanel';
import LabelledDates from './LabelledDates';

export const StyledGrid = styled(Grid)`
  flex-direction: column;
`;

export const RichTextWithMargin = styled(RichTextWithModal)`
  margin-top: ${spacing(3)};

  p {
    ${fonts.paragraphSmall}
  }

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
    
    p{
      ${fonts.paragraph}
    }
  `}
`;

export const PricePanelHeading = styled.h3`
  ${fonts.headingXSmall}
  margin: ${spacing(4)} 0 ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    ${fonts.headingSmall}
  `}
`;

export const StyledInfoPanel = styled(InfoPanel)`
  margin: ${spacing(3)} 0 ${spacing(-1)};

  && {
    padding: ${spacing(2)} ${spacing(3)} ${spacing(3)} ${spacing(2)};

    p {
      font-weight: ${lightFontWeight};
    }

    ${mediaQuery.tabletLandscape`
      padding: ${spacing(3)} ${spacing(2)};
      margin-bottom: ${spacing(-2)};

      p {
        ${fonts.paragraphLarge}
      }
    `}
  }
`;

export const LabelledDatesWithMargin = styled(LabelledDates)`
  margin-top: ${spacing(6)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(9)};
  `}
`;
