import { ActionLink } from '@rsa-digital/evo-shared-components/components/Link';
import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';

export const StyledPanel = styled(SimplePanel)`
  display: flex;
  flex-direction: column;
  align-items: center;

  && {
    padding: ${spacing(3)};

    ${mediaQuery.tabletLandscape`
      padding: ${spacing(4)};
    `}
  }
`;

export const StyledParagraph = styled.p`
  ${fonts.paragraph}
  text-align: center;
  margin: 0;
`;

export const StyledSpanHeadingMedium = styled.span`
  ${fonts.headingMedium}
  margin: ${spacing(1)} 0 0;
  display: block;
`;

export const ActionLinkWithTopMargin = styled(ActionLink)`
  margin-top: ${spacing(2)};
`;

export const StyledSpan = styled.span`
  ${fonts.paragraph}
  margin: 0;

  && {
    font-weight: ${heavyFontWeight};
  }
`;
