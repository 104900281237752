import SortCodeInput from '@rsa-digital/evo-shared-components/components/Form/SortCodeInput';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import useMonthlyPayment from 'apiHelpers/payment/useMonthlyPayment';
import DirectDebitLogo from 'assets/directdebitdesktop.svg';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import NameInput from 'components/NameInput';
import QuestionField from 'components/QuestionField';
import SelectInput from 'components/SelectInput';
import { ErrorPanel } from 'components/StatusPanel';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormSubmissionErrors,
  trackFormTextFieldFocus,
} from 'helpers/eventTracking';
import { INPUT_REGEX_NAME } from 'helpers/inputRegexes';
import useLoadingState from 'helpers/useLoadingState';
import useReferenceData from 'state/referenceData/useReferenceData';
import useBankPaymentContent from './content';
import { SectionHeadingWithIconsAndMargin, StyledErrorContainer } from './styles';
import { DirectDebitDetails } from './validation';

type BankPaymentProps = {
  details: DirectDebitDetails;
  updateCollectionAmount: (amount: number | undefined) => void;
  updateDetails: (update: Partial<DirectDebitDetails>) => void;
  showValidation: FieldFunction<DirectDebitDetails, void>;
  getError: FieldFunction<DirectDebitDetails, string | undefined>;
};

type LoadingSpinnerProps = {
  csPetGlobalConfig: {
    loading_spinner: {
      payment_dates_spinner_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetGlobalConfig {
      loading_spinner {
        payment_dates_spinner_text
      }
    }
  }
`;

const BankPayment: React.FC<BankPaymentProps> = ({
  details,
  updateCollectionAmount,
  updateDetails,
  showValidation,
  getError,
}) => {
  const {
    csPetGlobalConfig: {
      loading_spinner: { payment_dates_spinner_text },
    },
  } = useStaticQuery<LoadingSpinnerProps>(query);

  const { questions, labels, errors } = useBankPaymentContent();
  const daysRefData = useReferenceData('days')?.days;

  const { updateMonthlyPaymentSchedule } = useMonthlyPayment();

  const {
    isLoading: isUpdatingPaymentSchedule,
    withLoadingState: updatePaymentScheduleWithLoadingState,
  } = useLoadingState();

  const sectionHeadingId = 'bank-payment-section-heading';

  const getBankValidationError = (): string | undefined => {
    if (details.bankDetails.status === 'FAILURE') {
      scrollAndFocusInput('accountNumber');
      trackFormSubmissionErrors({ error: 'accountNumber,accountSortCode' });
      return errors.bankDetailsNotFound;
    }

    return undefined;
  };

  return (
    <Grid as="section" aria-labelledby={sectionHeadingId} alignLeft>
      {isUpdatingPaymentSchedule && (
        <LoadingOverlayV2
          id="updating_payment"
          loadingMessage={payment_dates_spinner_text}
          timeDuration={10}
        />
      )}
      <GridItem>
        <SectionHeadingWithIconsAndMargin heading={labels.heading} id={sectionHeadingId}>
          <img src={DirectDebitLogo} alt="Direct Debit Logo" />
        </SectionHeadingWithIconsAndMargin>
      </GridItem>
      {details.paymentError && (
        <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
          <ErrorPanel id="payment-error" aria-live="assertive">
            <RichText html={errors.paymentFailure} />
          </ErrorPanel>
        </GridItem>
      )}
      <GridItem>
        <QuestionField
          question={questions.isAccountInName}
          errorText={getError('isAccountInName')}>
          <BooleanRadioInput
            id="isAccountInName"
            value={details.isAccountInName}
            analyticsDescription="Is account in name"
            onChange={(value) => {
              updateDetails({ isAccountInName: value });
              showValidation('isAccountInName');
            }}
          />
        </QuestionField>
        <QuestionField
          question={questions.accountHolderName}
          errorText={getError('accountHolderName')}>
          <NameInput
            id="accountHolderName"
            value={details.accountHolderName}
            onChange={(e) => {
              if (e.target.value.match(INPUT_REGEX_NAME)) {
                updateDetails({ accountHolderName: e.target.value });
              }
            }}
            maxLength={40}
            onFocus={trackFormTextFieldFocus('Account holder name')}
            onBlur={() => {
              showValidation('accountHolderName');
            }}
          />
        </QuestionField>
        <StyledErrorContainer
          errorText={getBankValidationError()}
          id="accountNumberSortCodeErrorContainer-error"
          errorId="accountNumberSortCode-error">
          <QuestionField
            question={questions.accountNumber}
            errorText={getError('accountNumber')}>
            <TextInput
              id="accountNumber"
              numbersOnly
              maxLength={9}
              value={details.accountNumber}
              onChange={(e) =>
                updateDetails({
                  accountNumber: e.target.value,
                  bankDetails: { status: 'NONE' },
                })
              }
              onFocus={trackFormTextFieldFocus('Account number')}
              onBlur={() => {
                showValidation('accountNumber');
              }}
              inputMode="numeric"
            />
          </QuestionField>
          <QuestionField
            question={questions.accountSortCode}
            errorText={getError('accountSortCode')}>
            <SortCodeInput
              id="accountSortCode"
              value={details.accountSortCode}
              onChange={(sortCode) =>
                updateDetails({
                  accountSortCode: sortCode,
                  bankDetails: { status: 'NONE' },
                })
              }
              onFocus={trackFormTextFieldFocus('Account sort code')}
              onBlur={() => {
                showValidation('accountSortCode');
              }}
            />
          </QuestionField>
        </StyledErrorContainer>
      </GridItem>
      <GridItem>
        <QuestionField
          question={questions.monthlyPaymentDate}
          errorText={getError('monthlyPaymentDate')}>
          <SelectInput
            id="monthlyPaymentDate"
            value={details.monthlyPaymentDate}
            options={daysRefData ?? []}
            placeholder={questions.monthlyPaymentDate.placeholder}
            onChange={(e) => {
              updateDetails({
                monthlyPaymentDate: e.target.value,
              });
              updatePaymentScheduleWithLoadingState(() =>
                updateMonthlyPaymentSchedule(Number(e.target.value))
              ).then(updateCollectionAmount);
              trackFormDropdownSelect('Monthly payment date', e.target.value);
            }}
            onFocus={trackFormDropdownFocus('Monthly payment date', PageTitle.Payment)}
            onBlur={() => showValidation('monthlyPaymentDate')}
            loading={!daysRefData}
          />
        </QuestionField>
      </GridItem>
    </Grid>
  );
};

export default BankPayment;
