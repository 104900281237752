import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import {
  Container,
  Heading,
  HeadingAndLogoGridItem,
  HeadingsWrapper,
  LogoWrapper,
  StyledGrid,
  Subheading,
} from './styles';

export type SectionHeadingWithIconsProps = {
  heading: string;
  subheading?: string;
} & ComponentProps;

const SectionHeadingWithIcons: React.FC<SectionHeadingWithIconsProps> = ({
  heading,
  subheading,
  children,
  ...props
}) => (
  <Container {...componentProps(props)}>
    <StyledGrid>
      <HeadingAndLogoGridItem>
        <HeadingsWrapper>
          <Heading id="card-payment-heading">{heading}</Heading>
          {subheading && <Subheading>{subheading}</Subheading>}
        </HeadingsWrapper>
        <LogoWrapper>{children}</LogoWrapper>
      </HeadingAndLogoGridItem>
    </StyledGrid>
  </Container>
);

export default SectionHeadingWithIcons;
