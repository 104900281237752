import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Heading, SignpostInfoPanel, TextWrapper } from './styles';

type CsCancellationSignpostProps = {
  csPetPaymentV2: {
    cancellation_signpost: {
      heading: string;
      body: string;
    };
  };
};

const query = graphql`
  query {
    csPetPaymentV2 {
      cancellation_signpost {
        heading
        body
      }
    }
  }
`;

const CancellationSignpost: React.FC = () => {
  const {
    csPetPaymentV2: { cancellation_signpost },
  } = useStaticQuery<CsCancellationSignpostProps>(query);

  return (
    <Grid as="section" alignLeft>
      <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
        <SignpostInfoPanel data-cy="cancellationSignpost">
          <TextWrapper>
            <Heading>{cancellation_signpost.heading}</Heading>
            <RichText html={cancellation_signpost.body} />
          </TextWrapper>
        </SignpostInfoPanel>
      </GridItem>
    </Grid>
  );
};

export default CancellationSignpost;
