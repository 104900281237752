import { getCoverLevelFromQuoteOptions } from 'apiHelpers/quote/bundleCoverMapping';
import { lowVetFeesLimitSelected } from 'pages/pet/quote-summary/validation';
import { QuoteOptions } from 'state/formData/quoteOptions';

export const isQuoteSummaryDetailsInvalid = (quoteOptions: QuoteOptions): boolean => {
  const coverLevel = getCoverLevelFromQuoteOptions(quoteOptions);

  /*  Details invalid if no cover level is selected */
  return (
    coverLevel === undefined ||
    (lowVetFeesLimitSelected(quoteOptions) &&
      quoteOptions.hasConfirmedVetFeesLimit !== true) ||
    quoteOptions.excessPercentageConfirmations.some(
      (confirmation) => confirmation.hasConfirmedExcessPercentage !== true
    )
  );
};
