import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import {
  ActionLinkWithTopMargin,
  StyledPanel,
  StyledParagraph,
  StyledSpan,
  StyledSpanHeadingMedium,
} from './styles';

type PanelWithActionLinkProps = {
  summaryText: string;
  heading: string;
  link?: {
    text: string;
    screenreaderText?: string;
    onClick: React.MouseEventHandler;
  };
} & ComponentProps;

const PanelWithActionLink: React.FC<PanelWithActionLinkProps> = ({
  summaryText,
  heading,
  link,
  ...props
}) => (
  <StyledPanel {...componentProps(props)} borderShadows={{ displayOutset: true }}>
    <StyledParagraph aria-live="polite" aria-atomic="true">
      <StyledSpan>{summaryText}</StyledSpan>
      <StyledSpanHeadingMedium>{heading}</StyledSpanHeadingMedium>
    </StyledParagraph>
    {link && (
      <ActionLinkWithTopMargin onClick={link.onClick} aria-label={link.screenreaderText}>
        {link.text}
      </ActionLinkWithTopMargin>
    )}
  </StyledPanel>
);

export default PanelWithActionLink;
