import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import DirectDebitLogoDesktop from 'assets/directdebitdesktop.svg';
import DirectDebitLogoMobile from 'assets/directdebitmobile.svg';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import useDeclarationContent from './content';
import {
  DeclarationHeading,
  DeclarationHeadingWrapper,
  DeclarationWithBottomMargin,
  GridWithMargin,
  InfoPanelWithMargin,
  LargeRichText,
  RichTextWithFonts,
  StyledImageDesktop,
  StyledImageMobile,
} from './styles';

export type DeclarationDetails = {
  hasAgreedToDeclaration: boolean | undefined;
};

type DeclarationSectionProps = {
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  getError: FieldFunction<DeclarationDetails, string | undefined>;
} & ComponentProps;

const DeclarationSection: React.FC<DeclarationSectionProps> = ({
  hasAgreed,
  updateHasAgreed,
  getError,
}) => {
  const content = useDeclarationContent();

  return (
    <>
      <InfoPanelWithMargin data-cy="important-info-panel">
        <RichTextWithFonts
          pageTitle={PageTitle.Payment}
          html={content.importantInformation}
        />
      </InfoPanelWithMargin>
      <DeclarationWithBottomMargin
        id="declaration"
        hasAgreed={hasAgreed}
        updateHasAgreed={updateHasAgreed}
        error={getError('hasAgreedToDeclaration')}
        questionId="hasAgreedToDeclaration"
        questionText={content.questionText}
        gridItemProps={{ desktop: 6, tabletLandscape: 6, tabletPortrait: 6 }}>
        <GridWithMargin alignLeft>
          <GridItem>
            <DeclarationHeadingWrapper>
              <DeclarationHeading>{content.guaranteeHeading}</DeclarationHeading>
              <StyledImageDesktop src={DirectDebitLogoDesktop} alt="Direct Debit Logo" />
              <StyledImageMobile src={DirectDebitLogoMobile} alt="Direct Debit Logo" />
            </DeclarationHeadingWrapper>
            <LargeRichText pageTitle={PageTitle.Payment} html={content.guaranteeText} />
          </GridItem>
        </GridWithMargin>
      </DeclarationWithBottomMargin>
    </>
  );
};

export default DeclarationSection;
