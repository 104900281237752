import { graphql, useStaticQuery } from 'gatsby';

type DeclarationContent = {
  questionText: string;
  importantInformation: string;
  guaranteeText: string;
  guaranteeHeading: string;
};

type csDeclarationContent = {
  csPetMonthlyPayment: {
    declaration: {
      information: string;
      guarantee_heading: string;
      guarantee_text: string;
      direct_debit_guarantee_agreement: {
        question_text: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetMonthlyPayment {
      declaration {
        information
        guarantee_heading
        guarantee_text
        direct_debit_guarantee_agreement {
          question_text
        }
      }
    }
  }
`;

const useDeclarationContent = (): DeclarationContent => {
  const csContent = useStaticQuery<csDeclarationContent>(query).csPetMonthlyPayment
    .declaration;

  return {
    questionText: csContent.direct_debit_guarantee_agreement.question_text,
    importantInformation: csContent.information,
    guaranteeText: csContent.guarantee_text,
    guaranteeHeading: csContent.guarantee_heading,
  };
};

export default useDeclarationContent;
