import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';

export const QuestionFieldWithMargin = styled(QuestionField)<{
  checkDetailDeclarationStyle?: boolean;
}>`
  margin-top: ${(p) => (p.checkDetailDeclarationStyle ? 0 : spacing(4))};
  margin-bottom: ${(p) => (p.checkDetailDeclarationStyle ? spacing(1) : spacing(4))};
  padding: ${(p) => (p.checkDetailDeclarationStyle ? spacing(2) : 0)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${(p) => (p.checkDetailDeclarationStyle ? 0 : spacing(4))};
    margin-bottom: ${(p) => (p.checkDetailDeclarationStyle ? 0 : spacing(4))};
    padding: ${(p) => (p.checkDetailDeclarationStyle ? spacing(3) : 0)};
    `}
`;
